import * as zod from 'zod';

import {
  CustomDeviceActionConfiguration,
  CustomDeviceConfiguration,
} from '@sb/integrations/implementations/CustomDevice/types/CustomDeviceConfiguration';

export default zod.object({
  equipment: CustomDeviceConfiguration,
  action: CustomDeviceActionConfiguration,
});
