import type * as zod from 'zod';

import type {
  CustomDeviceActionConfiguration,
  CustomDeviceConfiguration,
} from '@sb/integrations/implementations/CustomDevice/types/CustomDeviceConfiguration';
import { FailureKind } from '@sb/routine-runner/FailureKind';
import type { AnyOutputPortID } from '@sb/routine-runner/types';
import { IOLevel } from '@sb/routine-runner/types';

import type { StepPlayArguments } from '../Step';
import Step from '../Step';

import Arguments from './Arguments';
import Variables from './Variables';

type Arguments = zod.infer<typeof Arguments>;

type Variables = zod.infer<typeof Variables>;

export default class OperateEquipmentStep extends Step<Arguments> {
  public static areSubstepsRequired = false;

  public static Arguments = Arguments;

  public static Variables = Variables;

  public initializeVariableState(): void {
    this.variables = {};
  }

  public async _play({ fail }: StepPlayArguments): Promise<void> {
    try {
      await this.handleOperateEquipment(this.args.equipment, this.args.action);
    } catch (error) {
      return fail({
        failure: { kind: FailureKind.ExecutionFailure },
        failureReason: 'Equipment action failed',
        error,
      });
    }
  }

  private async handleOperateEquipment(
    _equipment: CustomDeviceConfiguration,
    action: CustomDeviceActionConfiguration,
  ) {
    if (action.actionOutputs?.kind === 'controlBoxIO') {
      const changes = action.actionOutputs?.outputs
        .filter((output) => output.ioPort != null)
        .map((output) => ({
          label: output.ioPort as AnyOutputPortID,
          level: output.isHigh ? IOLevel.Values.high : IOLevel.Values.low,
        }));

      await this.routineContext.robot.setOutputIO(changes);
    }
  }
}
